import { createSlice } from "@reduxjs/toolkit";

var initialState = {
  //participantRecords: [],
  participantDetails: [],
  accidentFreeDaysDetails: [],
  activitiesDetails: [],
  securityAlertDetails: [],
  goodShortsToHighlightDetails: [],
  securityAlertLookup: [],
  activeSecurityAlertLookup: [],
  activitiesLookup: [],
  activeActivitiesLookUp: [],
  sSTRisksLookup: [],
  activeSSTRiskLookUp: [],
  riskRecuctionMeasureLookup: [],
  activeRiskReductionLookUp: [],
  accidentFreeRecord: null,
  securityAltertRecord: null,
  goodShotsToHighlightRecord: null,
  actvivtiesRecord: null,
  //diagramDetails: [],
  projectBreakpointRecords: {
    diagramDetails: [],
    participantRecords: [],
  },
  userLookup: [],
};
export const ProjectBreakPointSlice = createSlice({
  name: "ProjectBreakPoint",
  initialState: initialState,
  reducers: {
    resetBreakpointState: (state) => {
      state = initialState;
    },
    getProjectBreakpointRecords: (state, action) => {
      state.projectBreakpointRecords = action.payload;
      state.projectBreakpointRecords.diagramDetails =
        action.payload.diagramDetails;
      state.projectBreakpointRecords.participantRecords =
        action.payload.participantRecords;
      state.accidentFreeDaysDetails = action.payload.accidentFreeDays;
      state.activitiesDetails = action.payload.activityDetails;
      state.securityAlertDetails = action.payload.securityAlertDetails;
      state.goodShortsToHighlightDetails =
        action.payload.goodShotsToHighlightDetails;
    },
    getProjectBreakpointParticipantRecords: (state, action) => {
      state.projectBreakpointRecords.participantRecords = action.payload;
    },
    getParticipantDetails: (state, action) => {
      state.participantDetails = action.payload;
    },
    getDiagramDetails: (state, action) => {
      state.projectBreakpointRecords.diagramDetails = action.payload;
    },
    getAccidentFreeDaysDetails: (state, action) => {
      state.accidentFreeDaysDetails = action.payload;
    },
    getSecurityAlertDetails: (state, action) => {
      state.securityAlertDetails = action.payload;
    },
    getActivitiesDetails: (state, action) => {
      state.activitiesDetails = action.payload;
    },
    getGoodShortsToHighlightsDetails: (state, action) => {
      state.goodShortsToHighlightDetails = action.payload;
    },

    getAccidentFreeDaysRecord: (state, action) => {
      state.accidentFreeRecord = action.payload;
    },
    getSecurityAlertRecord: (state, action) => {
      state.securityAltertRecord = action.payload;
    },
    getActivitiesRecord: (state, action) => {
      state.accidentFreeRecord = action.payload;
    },
    getGoodShortsToHighlightsRecord: (state, action) => {
      state.goodShotsToHighlightRecord = action.payload;
    },
    getSecurityAlterLookup: (state, action) => {
      state.securityAlertLookup = action.payload;
    },
    getActiveSecurityAlterLookup: (state, action) => {
      state.activeSecurityAlertLookup = action.payload;
    },
    getActivitiesLookup: (state, action) => {
      state.activitiesLookup = action.payload;
    },
    getActiveActivitiesLookup: (state, action) => {
      state.activeActivitiesLookUp = action.payload;
    },
    getSSTRiskLookup: (state, action) => {
      state.sSTRisksLookup = action.payload;
    },
    getActiveSSTRiskLookup: (state, action) => {
      state.activeSSTRiskLookUp = action.payload;
    },
    getRiskReductionMeasureLookup: (state, action) => {
      state.riskRecuctionMeasureLookup = action.payload;
    },
    getActiveRiskReductionMeasureLookup: (state, action) => {
      state.activeRiskReductionLookUp = action.payload;
    },
    getUserLookup: (state, action) => {
      state.userLookup = action.payload;
    },
  },
});

export const {
  resetBreakpointState,
  getProjectBreakpointRecords,
  getProjectBreakpointParticipantRecords,
  getParticipantDetails,
  getDiagramDetails,
  getAccidentFreeDaysDetails,
  getActivitiesDetails,
  getGoodShortsToHighlightsDetails,
  getSecurityAlertDetails,
  getAccidentFreeDaysRecord,
  getActivitiesRecord,
  getGoodShortsToHighlightsRecord,
  getSecurityAlertRecord,
  getSecurityAlterLookup,
  getActiveSecurityAlterLookup,
  getActivitiesLookup,
  getActiveActivitiesLookup,
  getSSTRiskLookup,
  getActiveSSTRiskLookup,
  getRiskReductionMeasureLookup,
  getActiveRiskReductionMeasureLookup,
  getUserLookup,
} = ProjectBreakPointSlice.actions;

export default ProjectBreakPointSlice.reducer;
