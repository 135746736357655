import { createSlice } from "@reduxjs/toolkit";

export const ProductionSlice = createSlice({
  name: "Production",
  initialState: {
    productionRecords: [],
    loader: false,
  },
  reducers: {
    getProductionRecords: (state, action) => {
      state.productionRecords = action.payload;
    },
    showLoader(state) {
      state.loader = true;
    },
    hideLoader(state) {
      state.loader = false;
    },
  },
});

export const { getProductionRecords, showLoader, hideLoader } =
  ProductionSlice.actions;

export default ProductionSlice.reducer;
