import { createSlice } from "@reduxjs/toolkit";

export const SalesQuoteSlice = createSlice({
  name: "SalesQuote",
  initialState: {
    submittedQuotesList: [],
    draftedQuoteList: [],
    salesQuoteList: [],
    salesDraftList: [],
    quoteStatus: [],
    workItemTypes: [],
    activeWorkItemTypes: [],
    clientAfterSaved: [],
    clientDetails: [],
    savedQuoteDetails: null,
    cancelledQuoteList: [],
    savedWorkItems: [],
    salesCancelledList: [],
  },
  reducers: {
    getSubmittedQuoteRecords: (state, action) => {
      state.submittedQuotesList = action.payload;
      state.salesQuoteList = action.payload.salesQuotes;
    },
    getCancelledQuoteRecords: (state, action) => {
      state.cancelledQuoteList = action.payload;
      state.salesCancelledList = action.payload.salesQuotes;
    },
    getDraftedQuoteRecords: (state, action) => {
      state.draftedQuoteList = action.payload;
      state.salesDraftList = action.payload.salesQuotes;
    },
    getQuoteStatus: (state, action) => {
      state.quoteStatus = action.payload;
    },
    getAllWorkItemTypes: (state, action) => {
      state.workItemTypes = action.payload;
    },
    getAllActiveWorkItemTypes: (state, action) => {
      state.activeWorkItemTypes = action.payload;
    },
    getClientAfterSaved: (state, action) => {
      state.clientAfterSaved = action.payload;
    },
    getClientDetails: (state, action) => {
      state.clientDetails = action.payload;
    },
    getSavedWorkItems: (state, action) => {
      state.savedQuoteDetails = action.payload;
      state.savedWorkItems = action.payload.workItems;
    },
  },
});

export const {
  getSubmittedQuoteRecords,
  getQuoteStatus,
  getDraftedQuoteRecords,
  getAllWorkItemTypes,
  getAllActiveWorkItemTypes,
  getClientAfterSaved,
  getClientDetails,
  getSavedWorkItems,
  getCancelledQuoteRecords,
} = SalesQuoteSlice.actions;

export default SalesQuoteSlice.reducer;
