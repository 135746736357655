import { createSlice } from "@reduxjs/toolkit";

export const UsersSlice = createSlice({
  name: "Users",
  initialState: {
    userRecords: [],
    loader: false,
  },
  reducers: {
    getUserRecords: (state, action) => {
      state.userRecords = action.payload;
    },
    showLoader(state) {
      state.loader = true;
    },
    hideLoader(state) {
      state.loader = false;
    },
  },
});

export const { getUserRecords, showLoader, hideLoader } = UsersSlice.actions;

export default UsersSlice.reducer;
