import { lazy } from "react";

const routes = [
  {
    path: "dashboard",
    component: lazy(() => import("features/Dashboard/Dashboard")),
    exact: true,
  },
  {
    path: "adddailyupdate/:id?/:date?",
    component: lazy(() =>
      import("features/ProjectManagment/AddDailyUpdate/AddDailyUpdate")
    ),
    exact: true,
  },
  {
    path: "adddailyupdatedashboard/:id?/:date?",
    component: lazy(() =>
      import(
        "features/ProjectManagment/AddDailyUpdateDashboard/AddDailyUpdateDashboard"
      )
    ),
    exact: true,
  },
  {
    path: "breakpoint",
    component: lazy(() =>
      import("features/ProjectManagment/Breakpoint/Breakpoint")
    ),
    exact: true,
  },
  {
    path: "patrolling",
    component: lazy(() =>
      import("features/ProjectManagment/Patrolling/Patrolling")
    ),
    exact: true,
  },
  {
    path: "project",
    component: lazy(() => import("features/ProjectManagment/Project/Project")),
    exact: true,
  },
  {
    path: "quotes",
    component: lazy(() => import("features/CreateQuote/SalesQuotes")),
    exact: true,
  },
  {
    path: "createquote/:step/:quoteId?",
    component: lazy(() =>
      import("features/CreateQuote/Components/CreateQuote")
    ),
    exact: true,
  },
  {
    path: "estimate/:quoteId?",
    component: lazy(() => import("features/CreateQuote/Components/Estimate")),
    exact: true,
  },
  {
    path: "clientAccounting",
    component: lazy(() =>
      import(
        "features/ProjectManagment/Accounting/SubcontractorAccounting/SubcontractorDashboard"
      )
    ),
    exact: true,
  },
  {
    path: "subcontractorAccounting",
    component: lazy(() =>
      import(
        "features/ProjectManagment/Accounting/DesousaSubcontractor/DesousaSubcontractor"
      )
    ),
    exact: true,
  },
  {
    path: "subcontractorAccountingList",
    component: lazy(() =>
      import(
        "features/ProjectManagment/Accounting/DesousaInvoice/DesousaSubcontractorInvoice"
      )
    ),
    exact: true,
  },
  {
    path: "clientAccountingList",
    component: lazy(() =>
      import(
        "features/ProjectManagment/Accounting/SubcontractorAccounting/SubcontractorInvoice"
      )
    ),
    exact: true,
  },
  {
    path: "assignSubcontractor",
    component: lazy(() =>
      import("features/ProjectManagment/Accounting/AssignSubcontractor")
    ),
    exact: true,
  },
  {
    path: "masterDocument",
    component: lazy(() =>
      import(
        "features/ProjectManagment/Accounting/MasterDocument/MasterDocument"
      )
    ),
    exact: true,
  },
  {
    path: "quotesuccess/:quoteId?",
    component: lazy(() =>
      import("features/CreateQuote/Components/QuoteSuccess")
    ),
    exact: true,
  },
  {
    path: "settings",
    component: lazy(() => import("features/Settings/Settings")),
    exact: true,
  },
  {
    path: "subcontractors",
    component: lazy(() =>
      import("features/Settings/SubContractors/SubContractors")
    ),
    exact: true,
  },
  {
    path: "accounting",
    component: lazy(() =>
      import(
        "features/ProjectManagment/Accounting/DesousaInvoice/ProjectAccountingSummary"
      )
    ),
    exact: true,
  },
  {
    path: "suppliers",
    component: lazy(() => import("features/Settings/Suppliers/Suppliers")),
    exact: true,
  },
  {
    path: "settings/categorysuppliers/:id",
    component: lazy(() =>
      import("features/Settings/Suppliers/Components/CategorySuppliers")
    ),
    exact: true,
  },
  {
    path: "settings/CategoryActivities/:id",
    component: lazy(() =>
      import("features/Settings/Activities/Components/SstInActivities")
    ),
    exact: true,
  },
  // {
  //   path: 'users',
  //   component: lazy(() => import('features/Users')),
  //   exact: true,
  // },
];

export default routes;
