import { createSlice } from "@reduxjs/toolkit";

export const SubContractorsSlice = createSlice({
  name: "SubContractors",
  initialState: {
    subcontractorRecords: [],
    loader: false,
  },
  reducers: {
    getSubcontractorRecords: (state, action) => {
      state.subcontractorRecords = action.payload;
    },
    showLoader(state) {
      state.loader = true;
    },
    hideLoader(state) {
      state.loader = false;
    },
  },
});

export const { getSubcontractorRecords, showLoader, hideLoader } =
  SubContractorsSlice.actions;

export default SubContractorsSlice.reducer;
