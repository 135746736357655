import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
  dashboardList: [],
  workOrderByUPC: null,
  loader: false,
};

const DashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    onStart(state) {
      state.loader = true;
    },
    onSuccess(state, action) {
      state.loader = false;
      state.dashboardList = action.payload;
      var workOrder = action.payload.reduce((acc, obj) => {
        const key = obj.finalProductUPC;
        acc[key] = [];
        return acc;
      }, {});
      state.workOrderByUPC = workOrder;
    },
    onFailure(state) {
      state.loader = false;
    },
    onUpdateQtyToProduce(state) {
      state.loader = true;
    },
    onUpdateQtyToProduceSuccess(state) {
      state.loader = false;
    },
    onWorkOrderByUPCstart(state) {
      state.loader = true;
    },

    onWorkOrderByUPCSuccess(state, action) {
      if (action.payload?.length > 0) {
        state.workOrderByUPC = {
          ...state.workOrderByUPC,
          [action.payload[0].upc]: action.payload,
        };
      }
      state.loader = false;
    },
    onWorkOrderByUPCFailure(state, action) {
      state.loader = false;
    },
  },
});

export const {
  onStart,
  onSuccess,
  onFailure,
  onUpdateQtyToProduce,
  onUpdateQtyToProduceSuccess,
  onWorkOrderByUPCstart,
  onWorkOrderByUPCSuccess,
  onWorkOrderByUPCFailure,
} = DashboardSlice.actions;

export default DashboardSlice.reducer;
