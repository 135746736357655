import { createSlice } from "@reduxjs/toolkit";

export const AccountingMasterDocmentSlice = createSlice({
  name: "AccountingMasterDocment",
  initialState: {
    itemList: [],
    subContractorLookup: [],
  },
  reducers: {
    getProjectItemList: (state, action) => {
      state.itemList = action.payload;
    },
    getActiveSubcontractorLookup: (state, action) => {
      state.subContractorLookup = action.payload;
    },
  },
});

export const { getProjectItemList, getActiveSubcontractorLookup } =
  AccountingMasterDocmentSlice.actions;

export default AccountingMasterDocmentSlice.reducer;
