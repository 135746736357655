import React from "react";
import { useSelector } from "react-redux";

export function Authorization(givenrole) {
  const userRoles = localStorage.getItem("roles");
  let roletoCheck = givenrole;
  let result = false;
  if (userRoles) {
    //userRoles.forEach((role) => {
    if (roletoCheck.toLowerCase() === userRoles.toLowerCase()) {
      result = true;
    }
    //});
  }
  return result;
}

export function isAdmin() {
  return Authorization("admin");
}
export function isSuperAdmin() {
  return Authorization("admin");
}
export function isTeamLead() {
  return Authorization("Team Lead");
}
