import { createSlice } from "@reduxjs/toolkit";

export const ProjectSlice = createSlice({
  name: "Project",
  initialState: {
    projectRecords: [],
    clientLookup: [],
    allClientsLookup: [],
  },
  reducers: {
    getProjectRecords: (state, action) => {
      state.projectRecords = action.payload;
    },
    getClientLookup: (state, action) => {
      state.clientLookup = action.payload;
    },
    getAllClientLookup: (state, action) => {
      state.allClientsLookup = action.payload;
    },
  },
});
export const { getProjectRecords, getClientLookup, getAllClientLookup } =
  ProjectSlice.actions;

export default ProjectSlice.reducer;
