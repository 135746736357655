import { createSlice } from "@reduxjs/toolkit";

export const WorkItemTypeSlice = createSlice({
  name: "WorkItemType",
  initialState: {
    workItemRecords: [],
    loader: false,
  },
  reducers: {
    getWorkItemtypeRecords: (state, action) => {
      state.workItemRecords = action.payload;
    },
    showLoader(state) {
      state.loader = true;
    },
    hideLoader(state) {
      state.loader = false;
    },
  },
});

export const { getWorkItemtypeRecords, showLoader, hideLoader } =
  WorkItemTypeSlice.actions;

export default WorkItemTypeSlice.reducer;
