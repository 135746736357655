import React, { Suspense, useState, useContext } from "react";
import { FormattedMessage } from "react-intl";
import { Route, Switch, Link } from "react-router-dom";
import routes from "./routes";
import { Layout, Menu, Button, Avatar, Space, Row, Col, Select } from "antd";
import {
  CalendarOutlined,
  MenuOutlined,
  ToolOutlined,
  BarChartOutlined,
  ProjectOutlined,
  SnippetsOutlined,
  AuditOutlined,
  FlagOutlined,
} from "@ant-design/icons";
import logo from "../logo.png";
import logo1 from "../logo1.png";
import { Context } from "../components/Wrapper";
import { isAdmin } from "../features/OnBoarding/Authorizaton";
const { Header, Sider, Footer, Content } = Layout;
const { SubMenu } = Menu;
function ProtectedRoutes() {
  const [collapsed, setCollapsed] = useState(true);
  const context = useContext(Context);
  return (
    <Layout>
      <Layout>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          breakpoint="lg"
          defaultCollapsed={true}
          collapsedWidth="60"
          width={280}
        >
          <div className="new-logo">
            <img
              src={logo}
              className="img-fluid after-login-logo normal-logo"
              alt=""
            />
            <img
              src={logo1}
              className="img-fluid after-login-logo collapsed-logo"
              alt=""
            />
          </div>
          <div style={{ height: "50px" }}></div>
          <Menu theme="light" mode="inline" defaultSelectedKeys={["4"]}>
            <p className="project-heading-menu">Project Management</p>
            {process.env.REACT_APP_DAILYUPDATEDASHBOARD_TOGGLE == 1 && (
              <Menu.Item key="4" icon={<CalendarOutlined />}>
                <span>
                  <FormattedMessage id="Dashboard.Heading" />
                </span>
                <Link to="/adddailyupdatedashboard" />
              </Menu.Item>
            )}
            {process.env.REACT_APP_SETTINGS_PROJECT_TOGGLE == 1 && (
              <Menu.Item key="9" icon={<ProjectOutlined />}>
                <FormattedMessage id="Project.heading" />
                <Link to="/project" />
              </Menu.Item>
            )}
            {process.env.REACT_APP_ADDDAILYUPDATE_TOGGLE == 1 && (
              <Menu.Item key="2" icon={<SnippetsOutlined />}>
                <span>
                  <FormattedMessage id="AddDailyUpdate.Headings" />
                </span>
                <Link to="/adddailyupdate" />
              </Menu.Item>
            )}

            {process.env.REACT_APP_BREAKPOINT_TOGGLE == 1 && (
              <Menu.Item key="5" icon={<FlagOutlined />}>
                <span>
                  <FormattedMessage id="Breakpoint.Headings" />
                </span>
                <Link to="/breakpoint" />
              </Menu.Item>
            )}
            {process.env.REACT_APP_PATROLLING_TOGGLE == 1 && (
              <Menu.Item key="6" icon={<SnippetsOutlined />}>
                <FormattedMessage id="patrolling.heading" />
                <Link to="/patrolling" />
              </Menu.Item>
            )}
            {process.env.REACT_APP_ACCOUNTING_TOGGLE == 1 && (
              <Menu.Item key="8" icon={<SnippetsOutlined />}>
                <FormattedMessage id="Accounting.heading" />
                <Link to="/accounting" />
              </Menu.Item>
            )}
            {process.env.REACT_APP_SALESQUOTE_TOGGLE == 1 && (
              <Menu.Item key="7" icon={<AuditOutlined />}>
                <FormattedMessage id="Quotes.Heading" />
                <Link to="/quotes" />
              </Menu.Item>
            )}
            {process.env.REACT_APP_SETTINGS_TOGGLE == 1 && (
              <Menu.Item key="3" icon={<ToolOutlined />}>
                <FormattedMessage id="Settings.Equipments.Settings" />
                <Link to="/settings" />
              </Menu.Item>
            )}
            {process.env.REACT_APP_DASHBOARD_TOGGLE == 1 && (
              <Menu.Item key="1" icon={<BarChartOutlined />}>
                <FormattedMessage id="Dashboard.Heading" />
                <Link to="/dashboard" />
              </Menu.Item>
            )}
          </Menu>
        </Sider>
        <Layout
          className="site-layout"
          style={{
            overflow: "auto",
            minHeight: "100vh",
            width: "100%",
            paddingBottom: "56px",
            left: 0,
          }}
        >
          <Header
            className="site-layout-background"
            style={{
              padding: "0 20px 0 0",
              borderBottom: "solid 1px #eee",
            }}
          >
            <Row>
              <Col flex="auto" style={{ paddingLeft: "8px" }}>
                {React.createElement(collapsed ? MenuOutlined : MenuOutlined, {
                  className: "trigger",
                  onClick: () => setCollapsed(!collapsed),
                })}
              </Col>
              <Col flex="100px" className="langselect">
                <Select
                  value={context.locale}
                  onChange={context.selectLang}
                  bordered={false}
                >
                  <option value="en-US">English</option>
                  <option value="fr-FR">French</option>
                  {/* <option value="es-ES">Spanish</option> */}
                </Select>
              </Col>
              <Col flex="100px">
                <Space align="end">
                  <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                  &nbsp;
                  {
                    <a
                      onClick={() => {
                        localStorage.clear();
                        window.location.reload(true);
                      }}
                    >
                      <FormattedMessage id="Routes.Logout" />
                    </a>
                  }
                </Space>
              </Col>
            </Row>
          </Header>
          <Content
            className="site-layout-background content-padding"
            style={{
              margin: "0",
              minHeight: 280,
              textAlign: "justify",
            }}
          >
            <Switch>
              <Suspense fallback={<div>Loading...</div>}>
                {routes.map(({ component: Component, path, exact }, index) => (
                  <Route path={`/${path}`} key={index} exact={exact}>
                    <Component />
                  </Route>
                ))}
              </Suspense>
            </Switch>
          </Content>
          <Footer
            style={{
              borderTop: "1px solid #e8e8e8",
              position: "fixed",
              left: 0,
              bottom: 0,
              width: "100%",
              textAlign: "center",
            }}
          >
            <FormattedMessage id="Routes.footer" />
          </Footer>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default ProtectedRoutes;
