import { createSlice } from "@reduxjs/toolkit";

export const PatrollingSlice = createSlice({
  name: "PatrolingDailyUpdate",
  initialState: {
    patrollingRecords: [],
    weatherLookup: [],
    pavementLookup: [],
    findingsActionsLookup: [],
    patrolLeaderLookup: [],
    projectManagerLookUp: [],
    usersLookUp: [],
  },
  reducers: {
    getPatrollingRecords: (state, action) => {
      state.patrollingRecords = action.payload;
    },
    getWeatherRecords: (state, action) => {
      state.weatherLookup = action.payload;
    },
    getPavementRecords: (state, action) => {
      state.pavementLookup = action.payload;
    },
    getFindingsActionsRecords: (state, action) => {
      state.findingsActionsLookup = action.payload;
    },
    getPatrolLeaderRecords: (state, action) => {
      state.patrolLeaderLookup = action.payload;
    },
    getProjectManagerRecords: (state, action) => {
      state.projectManagerLookUp = action.payload;
    },
    getUserRecords: (state, action) => {
      state.usersLookUp = action.payload;
    },
  },
});

export const {
  getPatrollingRecords,
  getWeatherRecords,
  getPavementRecords,
  getFindingsActionsRecords,
  getPatrolLeaderRecords,
  getProjectManagerRecords,
  getUserRecords,
} = PatrollingSlice.actions;

export default PatrollingSlice.reducer;
