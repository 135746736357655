import { lazy, Suspense, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "./App.scss";
import ProtectedRoutes from "routes/ProtectedRoutes";
import { loginSessionAvailable } from "./features/OnBoarding/authenticationSlice";
import ResultPopup from "./features/CommonComponents/ResultPopup/ResultPopup";
const LoginPage = lazy(() => import("features/OnBoarding/LoginPage"));

function App() {
  const dispatch = useDispatch();
  //Getting isAuthenticated store value from Authentication slice.
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const isAuthenticatedState = useSelector(
    (state) => state.authentication.isAuthenticated
  );
  useEffect(() => {
    if (!isAuthenticatedState) {
      var isAuthenticateStorage = localStorage.getItem("isAuthenticated");
      var token_bearer = localStorage.getItem("token_bearer");
      setIsAuthenticated(isAuthenticateStorage);
      if (isAuthenticateStorage && token_bearer) {
        dispatch(loginSessionAvailable());
      } else {
        setIsAuthenticated(false);
      }
    } else {
      setIsAuthenticated(isAuthenticatedState);
    }
  }, [isAuthenticatedState, dispatch]);

  return (
    <>
      {isAuthenticated != null && (
        <Router>
          <ResultPopup />
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <PublicRoute path="/login" isAuthenticated={isAuthenticated}>
                <LoginPage />
              </PublicRoute>
              <PrivateRoute path="/dashboard" isAuthenticated={isAuthenticated}>
                <ProtectedRoutes />
              </PrivateRoute>
              <PrivateRoute
                path="/adddailyupdate"
                isAuthenticated={isAuthenticated}
              >
                <ProtectedRoutes />
              </PrivateRoute>
              <PrivateRoute
                path="/adddailyupdatedashboard"
                isAuthenticated={isAuthenticated}
              >
                <ProtectedRoutes />
              </PrivateRoute>
              <PrivateRoute
                path="/breakpoint"
                isAuthenticated={isAuthenticated}
              >
                <ProtectedRoutes />
              </PrivateRoute>
              <PrivateRoute
                path="/subcontractors"
                isAuthenticated={isAuthenticated}
              >
                <ProtectedRoutes />
              </PrivateRoute>
              <PrivateRoute path="/settings" isAuthenticated={isAuthenticated}>
                <ProtectedRoutes />
              </PrivateRoute>
              <PrivateRoute
                path="/patrolling"
                isAuthenticated={isAuthenticated}
              >
                <ProtectedRoutes />
              </PrivateRoute>
              <PrivateRoute path="/project" isAuthenticated={isAuthenticated}>
                <ProtectedRoutes />
              </PrivateRoute>
              <PrivateRoute path="/quotes" isAuthenticated={isAuthenticated}>
                <ProtectedRoutes />
              </PrivateRoute>
              <PrivateRoute
                path="/createquote"
                isAuthenticated={isAuthenticated}
              >
                <ProtectedRoutes />
              </PrivateRoute>
              <PrivateRoute path="/estimate" isAuthenticated={isAuthenticated}>
                <ProtectedRoutes />
              </PrivateRoute>
              <PrivateRoute
                path="/quotesuccess"
                isAuthenticated={isAuthenticated}
              >
                <ProtectedRoutes />
              </PrivateRoute>
              <PrivateRoute path="/suppliers" isAuthenticated={isAuthenticated}>
                <ProtectedRoutes />
              </PrivateRoute>
              <PrivateRoute path="/suppliers" isAuthenticated={isAuthenticated}>
                <ProtectedRoutes />
              </PrivateRoute>
              <PrivateRoute
                path="/accounting"
                isAuthenticated={isAuthenticated}
              >
                <ProtectedRoutes />
              </PrivateRoute>
              <PrivateRoute
                path="/clientAccounting"
                isAuthenticated={isAuthenticated}
              >
                <ProtectedRoutes />
              </PrivateRoute>

              <PrivateRoute
                path="/subcontractorAccounting"
                isAuthenticated={isAuthenticated}
              >
                <ProtectedRoutes />
              </PrivateRoute>
              <PrivateRoute
                path="/subcontractorAccountingList"
                isAuthenticated={isAuthenticated}
              >
                <ProtectedRoutes />
              </PrivateRoute>
              <PrivateRoute
                path="/clientAccountingList"
                isAuthenticated={isAuthenticated}
              >
                <ProtectedRoutes />
              </PrivateRoute>
              <PrivateRoute
                path="/masterDocument"
                isAuthenticated={isAuthenticated}
              >
                <ProtectedRoutes />
              </PrivateRoute>
              <PrivateRoute
                path="/assignSubcontractor"
                isAuthenticated={isAuthenticated}
              >
                <ProtectedRoutes />
              </PrivateRoute>
              <PrivateRoute path="/" isAuthenticated={isAuthenticated}>
                <ProtectedRoutes />
              </PrivateRoute>
            </Switch>
          </Suspense>
        </Router>
      )}
    </>
  );
}

// Private route restrict to access public pages after login.
function PrivateRoute({ children, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          rest.path === "/" ? (
            <Redirect
              to={{
                //pathname: "/dashboard",
                pathname: "/adddailyupdatedashboard",
                state: { from: location },
              }}
            />
          ) : (
            children
          )
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

// Public route restrict to access authenticated pages before login.
function PublicRoute({ children, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        !isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              //pathname: "/dashboard",
              pathname: "/adddailyupdatedashboard",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default App;
