import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loader: false,
  tip : ""
};

const GlobalSpinnerSlice = createSlice({
  name: "GlobalSpinner",
  initialState,
  reducers: {
    showLoader(state,action) {
      state.loader = true;
      state.tip = action.payload
    },
    hideLoader(state,action) {
      state.loader = false;
    },
  },
});

export const { showLoader, hideLoader } = GlobalSpinnerSlice.actions;

export default GlobalSpinnerSlice.reducer;
