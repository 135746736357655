import { createSlice } from "@reduxjs/toolkit";

export const FocusOnQualitySlice = createSlice({
  name: "FocusOnQuality",
  initialState: {
    focusOnQualityRecords: [],
    loader: false,
  },
  reducers: {
    getFocusOnQualityRecords: (state, action) => {
      state.focusOnQualityRecords = action.payload;
    },
    showLoader(state) {
      state.loader = true;
    },
    hideLoader(state) {
      state.loader = false;
    },
  },
});

export const { getFocusOnQualityRecords, showLoader, hideLoader } =
  FocusOnQualitySlice.actions;

export default FocusOnQualitySlice.reducer;
