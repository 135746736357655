const constants = {
  HOST_URL: process.env.REACT_APP_HOST_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  ActivityLookup : [
    {
      value: "A",
      label: "A : Préparer les machines, les équipements, les lieux",
    },
    {
      value: "B",
      label: "B: Tourner les pots (remplir terre)",
    },
    {
      value: "C",
      label: "C : Bouturer (prélèvement, plantation)",
    },
    {
      value: "D",
      label: "D : Faire les semis",
    },
    {
      value: "E",
      label: "E : Planter, Transplanter",
    },
    {
      value: "F",
      label: "F : Déplacer / espacer les produits, contenants",
    },
    {
      value: "G",
      label: "G :Arroser",
    },
    {
      value: "H",
      label: "H: Fertiliser/ pesticides",
    },
    {
      value: "I",
      label: "I : Entretenir, nettoyer la culture",
    },
    {
      value: "J",
      label: "J : Accrocher paniers",
    }
  ]
};

export default constants;
