import { createSlice } from "@reduxjs/toolkit";

export const AddDailyUpdateDashboardSlice = createSlice({
  name: "AddDailyUpdateDashboard",
  initialState: {
    projectRecords: [],
    dailyUpdateRecords: [],
    equipmentList: [],
    subContractorList: [],
    rentalsList: [],
    supplierList: [],
    dailyUpdateRecordsByDate: null,
  },
  reducers: {
    getProjectRecords: (state, action) => {
      state.projectRecords = action.payload;
    },
    getDailyUpdateRecords: (state, action) => {
      state.dailyUpdateRecords = action.payload;
    },
    getDailyUpdateRecordsByDate: (state, action) => {
      state.dailyUpdateRecordsByDate = action.payload;
      state.equipmentList = action.payload.dailyUpdateDashboardEquipmentList;
      state.subContractorList =
        action.payload.dailyUpdateDashboardSubContractorList;
      state.supplierList = action.payload.dailyUpdateDashboardSupplierList;
      state.rentalsList = action.payload.dailyUpdateDashboardRentalsList;
    },
  },
});

export const {
  getProjectRecords,
  getDailyUpdateRecords,
  getDailyUpdateRecordsByDate,
} = AddDailyUpdateDashboardSlice.actions;

export default AddDailyUpdateDashboardSlice.reducer;
