import { createSlice } from "@reduxjs/toolkit";

export const ClientSlice = createSlice({
  name: "SettingClients",
  initialState: {
    clientRecords: [],
    loader: false,
  },
  reducers: {
    getClientRecords: (state, action) => {
      state.clientRecords = action.payload;
    },
    showLoader(state) {
      state.loader = true;
    },
    hideLoader(state) {
      state.loader = false;
    },
  },
});

export const { getClientRecords, showLoader, hideLoader } =
ClientSlice.actions;

export default ClientSlice.reducer;
