import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
  equipmentLookup: [],
  categoryLookup: [],
  materialLookup: [],
  supplierLookup: [],
  projectLookup: [],
  subContractorLookup: [],
  equipmentList: [],
  supplierList: [],
  subContractorList: [],
  rentalsList: [],
  dailyUpdateFullDetails: null,
  equipmentById: null,
  subContractorById: null,
  supplierById: null,
  rentalById: null,
};

const DailyUpdateSlice = createSlice({
  name: "DailyUpdate",
  initialState,
  reducers: {
    //Lookup Reduce Methods
    onEquipmentLookupStart(state, action) {
      state.loader = true;
    },
    onEquipmentLookupSuccess(state, action) {
      state.loader = true;
      state.equipmentLookup = action.payload;
    },
    onCategoryLookupStart(state, action) {
      state.loader = true;
    },
    onCategoryLookupSuccess(state, action) {
      state.loader = true;
      state.categoryLookup = action.payload;
    },
    onMaterialLookupStart(state, action) {
      state.loader = true;
    },
    onMaterialLookupSuccess(state, action) {
      state.loader = true;
      state.materialLookup = action.payload;
    },
    onSubContractLookupStart(state, action) {
      state.loader = true;
    },
    onSubContractLookupSuccess(state, action) {
      state.loader = false;
      state.subContractorLookup = action.payload;
    },
    onProjetLookupStart(state, action) {
      state.loader = true;
    },
    onProjetLookupSuccess(state, action) {
      state.loader = true;
      state.projectLookup = action.payload;
    },
    onSupplierLookupStart(state, action) {
      state.loader = true;
    },
    onSupplierLookupSuccess(state, action) {
      state.loader = true;
      state.supplierLookup = action.payload;
    },
    // LookUp reduce methods end

    //DailyUpdate full details
    onGetDailyUpdateDetailsStart(state, action) {
      state.loader = false;
    },
    onGetDailyUpdateDetailsSuccess(state, action) {
      state.dailyUpdateFullDetails = action.payload;
      state.equipmentList = action.payload.projectDailyUpdateEquipmentList;
      state.subContractorList =
        action.payload.projectDailyUpdateSubContractorList;
      state.supplierList = action.payload.projectDailyUpdateSupplierList;
      state.rentalsList = action.payload.projectDailyUpdateRentalsList;
      state.loader = false;
    },

    //DailyUpdate Equipment Methods
    onDailyUpdateEquipmentSaveStart(state) {
      state.loader = true;
    },
    onDailyUpdateEquipmentUpdateStart(state) {
      state.loader = true;
    },
    onDailyUpdateEquipmentSaveSuccess(state, action) {
      state.loader = false;
    },
    onDUEquipmentUpdateSuccess(state, action) {
      state.loader = false;
    },
    onGetEquipmentByIdStart(state) {
      state.loader = true;
    },
    onGetEquipmentByIdSuccess(state, action) {
      state.loader = false;
      state.equipmentById = action.payload;
    },
    onGetEquipmentByDailyUpdateIdStart(state, action) {
      state.loader = true;
    },
    onGetEquipmentByDailyUpdateIdSuccess(state, action) {
      state.loader = false;
      state.equipmentList = action.payload;
    },
    onDUEquipmentDeleteStart(state, action) {
      state.loader = true;
    },
    onDUEquipmentDeleteSuccess(state, action) {
      state.loader = false;
    },

    //SubContractor Reduce Methods
    onGetDUSubContractorByDUIdStart(state, action) {
      state.loader = true;
    },
    onGetDUSubContractorByDUIdSuccess(state, action) {
      state.loader = false;
      state.subContractorList = action.payload;
    },
    onGetDUSubcontractorByIdStart(state, action) {
      state.loader = true;
    },
    onGetDUSubcontractorByIdSuccess(state, action) {
      state.loader = false;
      state.subContractorById = action.payload;
    },
    onDUSubContractorSaveStart(state, action) {
      state.loader = true;
    },
    onDUSubContractorSaveSuccess(state, action) {
      state.loader = false;
    },
    onDUSubContractorUpdateStart(state, action) {
      state.loader = true;
    },
    onDUSubContractorUpdateSuccess(state, action) {
      state.loader = false;
    },
    onDUSubContactorDeleteStart(state, action) {
      state.loader = true;
    },
    onDUSubContractorDeleteSuccess(state, action) {
      state.loader = false;
    },
    // SubContractor end

    //suppler start
    onGetDUSupplierByDUIdStart(state, action) {
      state.loader = true;
    },
    onGetDUSupplierByDUIdSuccess(state, action) {
      state.loader = false;
      state.supplierList = action.payload;
    },
    onGetDUSupplierByIdStart(state, action) {
      state.loader = true;
    },
    onGetDUSupplierByIdSuccess(state, action) {
      state.loader = false;
      state.supplierById = action.payload;
    },
    onDUSupplierSaveStart(state, action) {
      state.loader = true;
    },
    onDUSupplierSaveSuccess(state, action) {
      state.loader = false;
    },
    onDUSupplierUpdateStart(state, action) {
      state.loader = true;
    },
    onDUSupplierUpdateSuccess(state, action) {
      state.loader = false;
    },
    onDUSupplierDeleteStart(state, action) {
      state.loader = true;
    },
    onDUSupplierDeleteSuccess(state, action) {
      state.loader = false;
    },
    //supplier end

    //Rental Start
    onGetDURentalsByIdStart(state, action) {
      state.loader = true;
    },
    onGetDURentalsByIdSuccess(state, action) {
      state.loader = false;
      state.rentalById = action.payload;
    },
    onGetDURentalsByDUIdStart(state, action) {
      state.loader = true;
    },
    onGetDURentalsByDUIdSuccess(state, action) {
      state.loader = false;
      state.rentalsList = action.payload;
    },
    onDURentalSaveStart(state, action) {
      state.loader = true;
    },
    onDURentalSaveSuccess(state, action) {
      state.loader = false;
    },
    onDURentalUpdateStart(state, action) {
      state.loader = true;
    },
    onDURentalUpdateSuccess(state, action) {
      state.loader = false;
    },
    onDURentalDeleteStart(state, action) {
      state.loader = true;
    },
    onDURentalDeleteSuccess(state, action) {
      state.loader = false;
    },
    //Rental End

    onFailure(state, action) {
      state.loader = false;
    },
    onResetAllForms(state, action) {
      state.equipmentById = null;
      state.subContractorById = null;
      state.supplierById = null;
      state.rentalById = null;
    },
  },
});

export const {
  onDailyUpdateEquipmentSaveStart,
  onDailyUpdateEquipmentSaveSuccess,
  onDailyUpdateEquipmentUpdateStart,
  onGetDailyUpdateDetailsStart,
  onCategoryLookupStart,
  onCategoryLookupSuccess,
  onEquipmentLookupStart,
  onEquipmentLookupSuccess,
  onGetDailyUpdateDetailsSuccess,
  onMaterialLookupStart,
  onMaterialLookupSuccess,
  onProjetLookupStart,
  onProjetLookupSuccess,
  onSupplierLookupStart,
  onSupplierLookupSuccess,
  onSubContractLookupStart,
  onSubContractLookupSuccess,
  onGetEquipmentByIdStart,
  onGetEquipmentByIdSuccess,
  onGetEquipmentByDailyUpdateIdStart,
  onGetEquipmentByDailyUpdateIdSuccess,
  onDUEquipmentUpdateSuccess,
  onGetDUSubContractorByDUIdStart,
  onGetDUSubContractorByDUIdSuccess,
  onGetDUSubcontractorByIdStart,
  onGetDUSubcontractorByIdSuccess,
  onDUSubContractorSaveStart,
  onDUSubContractorSaveSuccess,
  onDUSubContractorUpdateStart,
  onDUSubContractorUpdateSuccess,
  onDUEquipmentDeleteStart,
  onDUEquipmentDeleteSuccess,
  onDUSubContactorDeleteStart,
  onDUSubContractorDeleteSuccess,
  onDUSupplierDeleteStart,
  onGetDURentalsByDUIdStart,
  onGetDURentalsByDUIdSuccess,

  onGetDURentalsByIdStart,
  onGetDURentalsByIdSuccess,
  onDURentalSaveStart,
  onDURentalSaveSuccess,
  onDURentalDeleteStart,
  onDURentalDeleteSuccess,
  onDURentalUpdateStart,
  onDURentalUpdateSuccess,
  onDUSupplierDeleteSuccess,
  onDUSupplierSaveStart,
  onDUSupplierSaveSuccess,
  onDUSupplierUpdateStart,
  onDUSupplierUpdateSuccess,
  onGetDUSupplierByDUIdStart,
  onGetDUSupplierByDUIdSuccess,
  onGetDUSupplierByIdStart,
  onGetDUSupplierByIdSuccess,
  onFailure,
  onResetAllForms,
} = DailyUpdateSlice.actions;

export default DailyUpdateSlice.reducer;
