import { createSlice } from "@reduxjs/toolkit";
//import axios from "axios";

export const EquipmentsSlice = createSlice({
  name: "Equipments",
  initialState: {
    equipmentRecords: [],
    loader: false,
  },
  reducers: {
    getEquipmentRecords: (state, action) => {
      state.equipmentRecords = action.payload;
    },
    showLoader(state) {
      state.loader = true;
    },
    hideLoader(state) {
      state.loader = false;
    },
  },
});

export const { getEquipmentRecords, showLoader, hideLoader } =
  EquipmentsSlice.actions;

export default EquipmentsSlice.reducer;
