import authentication from "features/OnBoarding/authenticationSlice";
import dashboard from "features/Dashboard/DashboardSlice";

import dailyUpdateSlice from "features/ProjectManagment/AddDailyUpdate/AddDailyUpdateSlice";

import Equipments from "features/Settings/Equipments/EquipmentsSlice";
import SubContractors from "features/Settings/SubContractors/SubContractorsSlice";
import Suppliers from "features/Settings/Suppliers/SuppliersSlice";
import Users from "features/Settings/Users/UsersSlice";
import ResultPopup from "features/CommonComponents/ResultPopup/ResultPopupSlice";
import AddDailyUpdateDashboard from "features/ProjectManagment/AddDailyUpdateDashboard/AddDailyUpdateDashboardSlice";
import Activities from "features/Settings/Activities/ActivitiesSlice";
import SecurityAlert from "features/Settings/SecurityAlerts/SecurityAlertSlice";
import FocusOnQuality from "features/Settings/FocusOnQuality/FocusOnQualitySlice";
import Production from "features/Settings/Production/ProductionSlice";
import ProjectBreakPoint from "features/ProjectManagment/Breakpoint/BreakpointSlice";
import PatrolingDailyUpdate from "features/ProjectManagment/Patrolling/PatrollingSlice";
import SalesQuote from "features/CreateQuote/SalesQuotesSlice";
import WorkItemType from "features/Settings/WorkItemType/WorkItemTypeSlice";
import Clients from "features/Settings/Client/ClientSlice";
import GlobalSpinner from "features/CommonComponents/GlobalSpinner/GlobalSpinnerSlice";
import Project from "features/ProjectManagment/Project/ProjectSlice";
import AccountingMasterDocment from "features/ProjectManagment/Accounting/MasterDocument/MasterDocumentSlice";
import SubcontractorAccounting from "features/ProjectManagment/Accounting/DesousaSubcontractor/DesousaSubcontractorSlice";

//Include all the reducer to combine and provide to configure store.

const rootReducer = {
  authentication,
  dashboard,

  dailyUpdateSlice,

  Equipments,
  SubContractors,
  Suppliers,
  Users,
  ResultPopup,

  AddDailyUpdateDashboard,
  Activities,
  SecurityAlert,
  FocusOnQuality,
  Production,
  ProjectBreakPoint,
  PatrolingDailyUpdate,
  SalesQuote,
  WorkItemType,
  Clients,
  GlobalSpinner,
  Project,

  AccountingMasterDocment,
  SubcontractorAccounting,
};

export default rootReducer;
