import { createSlice } from "@reduxjs/toolkit";

export const ActivitiesSlice = createSlice({
  name: "Activities",
  initialState: {
    activitiesRecords: [],
    sstrisksRecords: [],
    riskreductionRecords: null,
    loader: false,
  },
  reducers: {
    getActivityRecords: (state, action) => {
      state.activitiesRecords = action.payload;
    },
    getSSTRiskRecords: (state, action) => {
      state.sstrisksRecords = action.payload;
    },
    getRiskReductionRecords: (state, action) => {
      state.riskreductionRecords = {
        ...state.riskreductionRecords,
        [action.payload[0].sstriskId]: action.payload,
      };
    },
    showLoader(state) {
      state.loader = true;
    },
    hideLoader(state) {
      state.loader = false;
    },
  },
});

export const {
  getActivityRecords,
  getSSTRiskRecords,
  getRiskReductionRecords,
  showLoader,
  hideLoader,
} = ActivitiesSlice.actions;

export default ActivitiesSlice.reducer;
