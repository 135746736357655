import { all, call, put, takeLatest } from "redux-saga/effects";
import { login, loginSuccess, loginFailure } from "./authenticationSlice";
import { postRequest } from "app/axiosClient";
import { message } from "antd";

function* loginAPI(action) {
  message.destroy();
  try {
    const response = yield call(() =>
      postRequest("api/Accounts/Login", action.payload)
    );
    yield put(loginSuccess(response.data));
    message.success("Connexion réussie");
  } catch (e) {
    yield put(loginFailure());
    message.error("Nom d'utilisateur ou mot de passe invalide", 0);
  }
}

export default function* rootSaga() {
  yield all([takeLatest(login, loginAPI)]);
}
