import { createSlice } from "@reduxjs/toolkit";

export const SubcontractorAccountingSlice = createSlice({
  name: "SubcontractorAccounting",
  initialState: {
    ProjectsList: [],
    SubContractorItemsList: [],
  },
  reducers: {
    getSubcontractorsList: (state, action) => {
      state.ProjectsList = action.payload;
    },
  },
  reducers: {
    getItemsByProjectAndSubcontractor: (state, action) => {
      state.SubContractorItemsList = action.payload;
    },
  },
});

export const { getSubcontractorsList, getItemsByProjectAndSubcontractor } =
  SubcontractorAccountingSlice.actions;

export default SubcontractorAccountingSlice.reducer;
