import { createSlice } from "@reduxjs/toolkit";

export const SuppliersSlice = createSlice({
  name: "Suppliers",
  initialState: {
    suppliersRecords: [],
    categoryRecords: [],
    materialRecords: null,
    loader: false,
  },
  reducers: {
    getSuppliersRecords: (state, action) => {
      state.suppliersRecords = action.payload;
    },
    getCategoryRecords: (state, action) => {
      state.categoryRecords = action.payload;
    },
    getMaterialRecords: (state, action) => {
      state.materialRecords = {
        ...state.materialRecords,
        [action.payload[0].categoryId]: action.payload,
      };
    },
    showLoader(state) {
      state.loader = true;
    },
    hideLoader(state) {
      state.loader = false;
    },
  },
});

export const {
  getSuppliersRecords,
  getCategoryRecords,
  getMaterialRecords,
  showLoader,
  hideLoader,
} = SuppliersSlice.actions;

export default SuppliersSlice.reducer;
