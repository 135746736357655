import { all, call, put, takeLatest } from "redux-saga/effects";
import { useDispatch } from "react-redux";
import * as Actions from "./AddDailyUpdateSlice";
import { getRequest, postRequest } from "app/axiosClient";
import { FormattedMessage, useIntl } from "react-intl";
import { message } from "antd";
import moment from "moment";
import { showResultPopupMessage } from "features/CommonComponents/ResultPopup/ResultPopupSlice";
import { act } from "react-dom/test-utils";
import {
  showLoader,
  hideLoader,
} from "../../CommonComponents/GlobalSpinner/GlobalSpinnerSlice";
//Lookup Methods
function* getEquipmentLookup(action) {
  try {
    const response = yield call(() =>
      getRequest(`api/Lookup/GetEquipmentLookup`)
    );

    yield put(Actions.onEquipmentLookupSuccess(response.data));
  } catch (e) {
    yield put(Actions.onFailure());
    message.warning("Données d'équipement non disponibles");
  }
}

function* getProjectLookup(action) {
  try {
    const response = yield call(() =>
      getRequest(`api/Lookup/GetProjectLookup`)
    );

    yield put(Actions.onProjetLookupSuccess(response.data));
  } catch (e) {
    yield put(Actions.onFailure());
    message.warning("Données de projet non disponibles");
  }
}

function* getSupplierLookup(action) {
  try {
    const response = yield call(() =>
      getRequest(`api/Lookup/GetSupplierLookup`)
    );

    yield put(Actions.onSupplierLookupSuccess(response.data));
  } catch (e) {
    yield put(Actions.onFailure());
    message.warning("Données fournisseur non disponibles");
  }
}

function* getCategoryLookUp(action) {
  try {
    const response = yield call(() =>
      getRequest(`api/Lookup/GetCategoryLookup`)
    );

    yield put(Actions.onCategoryLookupSuccess(response.data));
  } catch (e) {
    yield put(Actions.onFailure());
    message.warning("Données de catégorie non disponibles");
  }
}
function* getMaterialLookup(action) {
  try {
    const response = yield call(() =>
      getRequest(`api/Lookup/GetMaterialLookup`)
    );

    yield put(Actions.onMaterialLookupSuccess(response.data));
  } catch (e) {
    yield put(Actions.onFailure());
    message.warning("Données matérielles non disponibles");
  }
}
function* getSubContractorLookup(action) {
  try {
    const response = yield call(() =>
      getRequest(`api/Lookup/GetSubContractorLookup`)
    );

    yield put(Actions.onSubContractLookupSuccess(response.data));
  } catch (e) {
    yield put(Actions.onFailure());
    message.warning("Données du sous-traitant non disponibles");
  }
}

//Full DailyUpdate details

function* getDailyUpdateFullDetails(action) {
  yield put(showLoader());
  try {
    const response = yield call(() =>
      postRequest(
        `api/DailyUpdate/GetProjectDailyUpdateDetails`,
        action.payload
      )
    );

    yield put(Actions.onGetDailyUpdateDetailsSuccess(response.data));
    yield put(hideLoader());
  } catch (e) {
    yield put(Actions.onFailure());
    message.warning("Données non disponibles");
    yield put(hideLoader());
  }
}

// DailyUpdatedEquipment Methods

function* getEquipmentListByDailyUpdateId(action) {
  yield put(showLoader());
  var id = action.payload || action;
  try {
    const response = yield call(() =>
      getRequest(`api/DailyUpdate/GetEquipmentByDailyUpdateId?id=${id}`)
    );

    yield put(Actions.onGetEquipmentByDailyUpdateIdSuccess(response.data));
    yield put(hideLoader());
  } catch (e) {
    yield put(Actions.onFailure());
    message.warning("Données non disponibles");
    yield put(hideLoader());
  }
}

function* getDailyUpdateEquipmentById(action) {
  yield put(showLoader());
  try {
    const response = yield call(() =>
      getRequest(
        `api/DailyUpdate/GetProjectDailyUpdateEquipmentById?id=${action.payload}`
      )
    );

    yield put(Actions.onGetEquipmentByIdSuccess(response.data));
    yield put(hideLoader());
  } catch (e) {
    yield put(Actions.onFailure());
    message.warning("Données non disponibles");
    yield put(hideLoader());
  }
}

function* savePrjectDailyUpdateEquipment(action) {
  yield put(showLoader());
  try {
    let path = `/adddailyupdatedashboard/${
      action.payload.dailyUpdateVM.projectId
    }/${moment(action.payload.dailyUpdateVM.dailyUpdateDate).format(
      "YYYYMMDD"
    )}`;

    const response = yield call(() =>
      postRequest(
        "api/DailyUpdate/AddProjectDailyUpdateEquipment",
        action.payload
      )
    );
    //message.success("Saved Equipment sucessfully");
    yield put(
      showResultPopupMessage({
        status: "success",
        title: (
          <FormattedMessage id="AddDailyUpdate.EquipmentdetailssavedsuccessfullyforDailyUpdate" />
        ),
        subTitle: "",
        showBackButton: true,
        backButtonRedirectPath: path,
        backButtonName: (
          <FormattedMessage id="AddDailyUpdate.GoBacktoDashboard" />
        ),
      })
    );
    yield put(hideLoader());
    var res = yield call(() => getEquipmentListByDailyUpdateId(response.data));
    yield put(Actions.onDailyUpdateEquipmentSaveSuccess(response.data));
  } catch (e) {
    yield put(Actions.onFailure());
    yield put(hideLoader());
    message.error("Une erreur interne s'est produite");
  }
}
function* updatePrjectDailyUpdateEquipment(action) {
  yield put(showLoader());
  try {
    let path = `/adddailyupdatedashboard/${
      action.payload.dailyUpdateVM.projectId
    }/${moment(action.payload.dailyUpdateVM.dailyUpdateDate).format(
      "YYYYMMDD"
    )}`;
    const response = yield call(() =>
      postRequest(
        "api/DailyUpdate/UpdateProjectDailyUpdateEquipment",
        action.payload
      )
    );
    yield put(
      showResultPopupMessage({
        status: "success",
        title: (
          <FormattedMessage id="AddDailyUpdate.EquipmentdetailsupdatedsuccessfullyforDailyUpdate" />
        ),
        subTitle: "",
        showBackButton: true,
        backButtonRedirectPath: path,
        backButtonName: (
          <FormattedMessage id="AddDailyUpdate.GoBacktoDashboard" />
        ),
      })
    );
    yield put(hideLoader());
    var res = yield call(() => getEquipmentListByDailyUpdateId(response.data));

    yield put(Actions.onDUEquipmentUpdateSuccess(response.data));
  } catch (e) {
    yield put(Actions.onFailure());

    message.warning("Une erreur interne s'est produite");
    yield put(hideLoader());
  }
}

function* deletePrjectDailyUpdateEquipment(action) {
  yield put(showLoader());
  var { Id } = action.payload;
  var { ProjectDailyUpdateId } = action.payload;
  try {
    var { projectId } = action.payload;
    let path = `/adddailyupdatedashboard/${action.payload.projectId}/${moment(
      action.payload.dailyUpdateDate
    ).format("YYYYMMDD")}`;
    const response = yield call(() =>
      postRequest(`api/DailyUpdate/DeleteProjectDailyUpdateEquipment?id=${Id}`)
    );
    yield put(
      showResultPopupMessage({
        status: "success",
        title: (
          <FormattedMessage id="AddDailyUpdate.EquipmentremovedsuccessfullyfromDailyUpdate" />
        ),
        subTitle: "",
        showBackButton: true,
        backButtonRedirectPath: path,
        backButtonName: (
          <FormattedMessage id="AddDailyUpdate.GoBacktoDashboard" />
        ),
      })
    );
    yield call(() => getEquipmentListByDailyUpdateId(ProjectDailyUpdateId));

    yield put(Actions.onDUEquipmentUpdateSuccess(response.data));
    yield put(hideLoader());
  } catch (e) {
    yield put(Actions.onFailure());

    message.warning("Une erreur interne s'est produite");
    yield put(hideLoader());
  }
}

//DailyUpdate Equipment Methods Ends

//DailyUpdate SubContractor Methods
function* getDailyUpdateSubControctorById(action) {
  yield put(showLoader());
  try {
    const response = yield call(() =>
      getRequest(
        `api/DailyUpdate/GetDailyUpdateSubContractorById?id=${action.payload}`
      )
    );

    yield put(Actions.onGetDUSubcontractorByIdSuccess(response.data));
    yield put(hideLoader());
  } catch (e) {
    yield put(Actions.onFailure());
    message.warning("Données non disponibles");
    yield put(hideLoader());
  }
}
function* getSubContractorByDailyUpdateId(action) {
  yield put(showLoader());
  var id = action.payload || action;
  try {
    const response = yield call(() =>
      getRequest(`api/DailyUpdate/GetSubContractsListByDailyUpdateId?id=${id}`)
    );

    yield put(Actions.onGetDUSubContractorByDUIdSuccess(response.data));
    yield put(hideLoader());
  } catch (e) {
    yield put(Actions.onFailure());
    message.warning("Données non disponibles");
    yield put(hideLoader());
  }
}

function* saveProjectDUESubContractor(action) {
  yield put(showLoader());
  try {
    let path = `/adddailyupdatedashboard/${
      action.payload.dailyUpdateVM.projectId
    }/${moment(action.payload.dailyUpdateVM.dailyUpdateDate).format(
      "YYYYMMDD"
    )}`;
    const response = yield call(() =>
      postRequest(
        "api/DailyUpdate/AddProjectDailyUpdateSubContractor",
        action.payload
      )
    );
    yield put(
      showResultPopupMessage({
        status: "success",
        title: (
          <FormattedMessage id="AddDailyUpdate.SubContractorsavedsucessfullyforDailyUpdate" />
        ),
        subTitle: "",
        showBackButton: true,
        backButtonRedirectPath: path,
        backButtonName: (
          <FormattedMessage id="AddDailyUpdate.GoBacktoDashboard" />
        ),
      })
    );
    var res = yield call(() => getSubContractorByDailyUpdateId(response.data));
    yield put(Actions.onDailyUpdateEquipmentSaveSuccess(response.data));
    yield put(hideLoader());
  } catch (e) {
    yield put(Actions.onFailure());

    message.error("Une erreur interne s'est produite");
    yield put(hideLoader());
  }
}
function* updateProjectDUSubContractor(action) {
  yield put(showLoader());
  try {
    let path = `/adddailyupdatedashboard/${
      action.payload.dailyUpdateVM.projectId
    }/${moment(action.payload.dailyUpdateVM.dailyUpdateDate).format(
      "YYYYMMDD"
    )}`;
    const response = yield call(() =>
      postRequest(
        "api/DailyUpdate/UpdateProjectDailyUpdateSubContractor",
        action.payload
      )
    );
    yield put(
      showResultPopupMessage({
        status: "success",
        title: (
          <FormattedMessage id="AddDailyUpdate.SubContractorupdatedsucessfullyforDailyUpdate" />
        ),
        subTitle: "",
        showBackButton: true,
        backButtonRedirectPath: path,
        backButtonName: (
          <FormattedMessage id="AddDailyUpdate.GoBacktoDashboard" />
        ),
      })
    );
    var res = yield call(() => getSubContractorByDailyUpdateId(response.data));

    yield put(Actions.onDUSubContractorUpdateSuccess(response.data));
    yield put(hideLoader());
  } catch (e) {
    yield put(Actions.onFailure());
    message.error("Une erreur interne s'est produite");
    yield put(hideLoader());
  }
}

function* deletePrjectDUSubContractor(action) {
  yield put(showLoader());
  var { Id } = action.payload;
  var { ProjectDailyUpdateId } = action.payload;
  var { projectId } = action.payload;
  try {
    let path = `/adddailyupdatedashboard/${action.payload.projectId}/${moment(
      action.payload.dailyUpdateDate
    ).format("YYYYMMDD")}`;
    const response = yield call(() =>
      postRequest(`api/DailyUpdate/DeleteDailyUpdateSubContractor?id=${Id}`)
    );
    yield put(
      showResultPopupMessage({
        status: "success",
        title: (
          <FormattedMessage id="AddDailyUpdate.SubContractorremovedsucessfullyfromDailyUpdate" />
        ),
        subTitle: "",
        showBackButton: true,
        backButtonRedirectPath: path,
        backButtonName: (
          <FormattedMessage id="AddDailyUpdate.GoBacktoDashboard" />
        ),
      })
    );
    var res = yield call(() =>
      getSubContractorByDailyUpdateId(ProjectDailyUpdateId)
    );

    yield put(Actions.onDUEquipmentUpdateSuccess(response.data));
    yield put(hideLoader());
  } catch (e) {
    yield put(Actions.onFailure());
    message.error("Une erreur interne s'est produite");
    yield put(hideLoader());
  }
}

//DailyUpdate SubContractor Methods End

//DailyUpdate Supplier Methods
function* getDailyUpdateSupplierById(action) {
  yield put(showLoader());
  const id = action.payload || action;
  try {
    const response = yield call(() =>
      getRequest(`api/DailyUpdate/GetDailyUpdateSubContractorById?id=${id}`)
    );

    yield put(Actions.onGetDUSupplierByIdSuccess(response.data));
    yield put(hideLoader());
  } catch (e) {
    yield put(Actions.onFailure());
    message.warning("Données non disponibles");
    yield put(hideLoader());
  }
}
function* getSupplierByDailyUpdateId(action) {
  yield put(showLoader());
  var id = action.payload || action;
  try {
    const response = yield call(() =>
      getRequest(`api/DailyUpdate/GetSupplierListByDailyUpdateId?id=${id}`)
    );

    yield put(Actions.onGetDUSupplierByDUIdSuccess(response.data));
    yield put(hideLoader());
  } catch (e) {
    yield put(Actions.onFailure());
    message.warning("Données non disponibles");
    yield put(hideLoader());
  }
}

function* saveProjectDUESupplier(action) {
  yield put(showLoader());
  try {
    let path = `/adddailyupdatedashboard/${
      action.payload.dailyUpdateVM.projectId
    }/${moment(action.payload.dailyUpdateVM.dailyUpdateDate).format(
      "YYYYMMDD"
    )}`;
    var deliveryChallan = action.payload?.UploadDeliveryChallan;
    delete action.payload?.UploadDeliveryChallan;
    const response = yield call(() =>
      postRequest("api/DailyUpdate/AddDailyUpdateSupplier", action.payload)
    );
    yield put(
      showResultPopupMessage({
        status: "success",
        title: (
          <FormattedMessage id="AddDailyUpdate.SuppliersavedsucessfullyforDailyUpdate" />
        ),
        subTitle: "",
        showBackButton: true,
        backButtonRedirectPath: path,
        backButtonName: (
          <FormattedMessage id="AddDailyUpdate.GoBacktoDashboard" />
        ),
      })
    );

    yield put(Actions.onDUSupplierSaveSuccess(response.data));

    var obj = {
      DeliveryChallan: deliveryChallan,
      DailyUpdateId: response.data?.dailyUpdateId,
      SupplierId: response.data?.supplierId,
    };
    yield call(() => uploadDeliveryChallan(obj));
    var res = yield call(() =>
      getSupplierByDailyUpdateId(response.data?.dailyUpdateId)
    );
    yield put(hideLoader());
  } catch (e) {
    yield put(Actions.onFailure());

    message.error("Une erreur interne s'est produite");
    yield put(hideLoader());
  }
}
function* updateProjectDUSupplier(action) {
  yield put(showLoader());
  var deliveryChallan = action.payload?.UploadDeliveryChallan;
  delete action.payload?.UploadDeliveryChallan;
  try {
    let path = `/adddailyupdatedashboard/${
      action.payload.dailyUpdateVM.projectId
    }/${moment(action.payload.dailyUpdateVM.dailyUpdateDate).format(
      "YYYYMMDD"
    )}`;
    const response = yield call(() =>
      postRequest(
        "api/DailyUpdate/UpdateProjectDailyUpdateSupplier",
        action.payload
      )
    );
    yield put(
      showResultPopupMessage({
        status: "success",
        title: (
          <FormattedMessage id="AddDailyUpdate.SupplierupdatedsucessfullyforDailyUpdate" />
        ),
        subTitle: "",
        showBackButton: true,
        backButtonRedirectPath: path,
        backButtonName: (
          <FormattedMessage id="AddDailyUpdate.GoBacktoDashboard" />
        ),
      })
    );
    yield put(Actions.onDUSupplierUpdateSuccess(response.data));

    var obj = {
      DeliveryChallan: deliveryChallan,
      DailyUpdateId: response.data?.dailyUpdateId,
      SupplierId: response.data?.supplierId,
    };
    yield call(() => uploadDeliveryChallan(obj));
    var res = yield call(() =>
      getSupplierByDailyUpdateId(response.data?.dailyUpdateId)
    );
    yield put(hideLoader());
  } catch (e) {
    yield put(Actions.onFailure());

    message.warning("Une erreur interne s'est produite");
    yield put(hideLoader());
  }
}

function* deletePrjectDUSupplier(action) {
  yield put(showLoader());
  try {
    var { Id } = action.payload;
    var { ProjectDailyUpdateId } = action.payload;
    var { projectId } = action.payload;
    let path = `/adddailyupdatedashboard/${action.payload.projectId}/${moment(
      action.payload.dailyUpdateDate
    ).format("YYYYMMDD")}`;
    const response = yield call(() =>
      postRequest(`api/DailyUpdate/DeleteDailyUpdateSupplier?id=${Id}`)
    );
    yield put(
      showResultPopupMessage({
        status: "success",
        title: (
          <FormattedMessage id="AddDailyUpdate.SupplierremovedsucessfullyfromDailyUpdate" />
        ),
        subTitle: "",
        showBackButton: true,
        backButtonRedirectPath: path,
        backButtonName: (
          <FormattedMessage id="AddDailyUpdate.GoBacktoDashboard" />
        ),
      })
    );
    var res = yield call(() =>
      getSupplierByDailyUpdateId(ProjectDailyUpdateId)
    );

    yield put(Actions.onDUSupplierDeleteSuccess(response.data));
    yield put(hideLoader());
  } catch (e) {
    yield put(Actions.onFailure());

    message.warning("Une erreur interne s'est produite");
    yield put(hideLoader());
  }
}
//DailyUpdate suppler ends

//DailyUpdateRental Start

function* getDailyUpdateRentalById(action) {
  yield put(showLoader());
  const id = action.payload || action;
  try {
    const response = yield call(() =>
      getRequest(`api/DailyUpdate/GetDailyUpdateRentalsById?id=${id}`)
    );

    yield put(Actions.onGetDUSupplierByIdSuccess(response.data));
    yield put(hideLoader());
  } catch (e) {
    yield put(Actions.onFailure());
    message.warning("Données non disponibles");
    yield put(hideLoader());
  }
}
function* getRentalByProjectId(action) {
  yield put(showLoader());
  var id = action.payload || action;
  try {
    const response = yield call(() =>
      getRequest(`api/DailyUpdate/GetRentalsListByProjectId?id=${id}`)
    );

    yield put(Actions.onGetDURentalsByDUIdSuccess(response.data));
    yield put(hideLoader());
  } catch (e) {
    yield put(Actions.onFailure());
    message.warning("Données non disponibles");
    yield put(hideLoader());
  }
}
function* saveProjectDUERentals(action) {
  yield put(showLoader());
  try {
    let path = `/adddailyupdatedashboard/${action.payload.ProjectId}/${moment(
      action.payload.dailyUpdateDate
    ).format("YYYYMMDD")}`;
    const response = yield call(() =>
      postRequest("api/DailyUpdate/AddDailyUpdateRentals", action.payload)
    );
    yield put(
      showResultPopupMessage({
        status: "success",
        title: (
          <FormattedMessage id="AddDailyUpdate.RentalssavedsucessfullyforDailyUpdate" />
        ),
        subTitle: "",
        showBackButton: true,
        backButtonRedirectPath: path,
        backButtonName: (
          <FormattedMessage id="AddDailyUpdate.GoBacktoDashboard" />
        ),
      })
    );

    var res = yield call(() => getRentalByProjectId(response.data));
    yield put(Actions.onDURentalSaveSuccess(response.data));
    yield put(hideLoader());
  } catch (e) {
    yield put(Actions.onFailure());

    message.warning("Une erreur interne s'est produite");

    yield put(hideLoader());
  }
}
function* updateProjectDURentals(action) {
  yield put(showLoader());
  try {
    let path = `/adddailyupdatedashboard/${action.payload.ProjectId}/${moment(
      action.payload.dailyUpdateDate
    ).format("YYYYMMDD")}`;
    const response = yield call(() =>
      postRequest(
        "api/DailyUpdate/UpdateProjectDailyUpdateRentals",
        action.payload
      )
    );
    yield put(
      showResultPopupMessage({
        status: "success",
        title: (
          <FormattedMessage id="AddDailyUpdate.RentalsupdatedsucessfullyforDailyUpdate" />
        ),
        subTitle: "",
        showBackButton: true,
        backButtonRedirectPath: path,
        backButtonName: (
          <FormattedMessage id="AddDailyUpdate.GoBacktoDashboard" />
        ),
      })
    );

    var res = yield call(() => getRentalByProjectId(response.data));

    yield put(Actions.onDURentalUpdateSuccess(response.data));
    yield put(hideLoader());
  } catch (e) {
    yield put(Actions.onFailure());

    showResultPopupMessage({
      status: "success",
      title: <FormattedMessage id="AddDailyUpdate.InternalErrorOccured" />,
      subTitle: "",
    });
    // message.warning(
    //   <FormattedMessage id="AddDailyUpdate.InternalErrorOccured" />
    // );
    yield put(hideLoader());
  }
}

function* deletePrjectDURentals(action) {
  yield put(showLoader());
  try {
    var { Id } = action.payload;
    var { ProjectId } = action.payload;
    let path = `/adddailyupdatedashboard/${action.payload.ProjectId}/${moment(
      action.payload.dailyUpdateDate
    ).format("YYYYMMDD")}`;
    const response = yield call(() =>
      postRequest(`api/DailyUpdate/DeleteDailyUpdateRentals?id=${Id}`)
    );
    yield put(
      showResultPopupMessage({
        status: "success",
        title: (
          <FormattedMessage id="AddDailyUpdate.RentalsremovedsucessfullyfromDailyUpdate" />
        ),
        subTitle: "",
        showBackButton: true,
        backButtonRedirectPath: path,
        backButtonName: (
          <FormattedMessage id="AddDailyUpdate.GoBacktoDashboard" />
        ),
      })
    );

    var res = yield call(() => getRentalByProjectId(ProjectId));

    yield put(Actions.onDURentalDeleteSuccess(response.data));
    yield put(hideLoader());
  } catch (e) {
    yield put(Actions.onFailure());

    message.warning("Une erreur interne s'est produite");
    yield put(hideLoader());
  }
}

//DailyUpdateRental end
function* uploadDeliveryChallan(action) {
  if (action?.DeliveryChallan.has("files")) {
    yield put(showLoader());
    try {
      const response = yield call(() =>
        postRequest(
          `api/DailyUpdate/UploadDeliveryChallan/${action?.DailyUpdateId}/${action?.SupplierId}`,
          action?.DeliveryChallan
        )
      );
      // message.success(
      //   <FormattedMessage id="AddDailyUpdate.AttachmentSavedsuccessfully" />
      // );
      // message.success(
      //   intl.formatMessage({
      //     id: "AddDailyUpdate.AttachmentSavedsuccessfully",
      //   })
      // );

      message.success("Pièce jointe enregistrée avec succès");
      // yield put(
      //   showResultPopupMessage({
      //     status: "success",
      //     title: "Attachment Saved successfully for Daily Update",
      //     subTitle: "",
      //   })
      // );
      yield put(hideLoader());
    } catch (e) {
      message.error("Pièce jointe non enregistrée");
      yield put(hideLoader());
    }
  }
}
export const saveProjectDailyUpdate = (values) => (dispatch) => {
  // yield put(showLoader());
  try {
    let path = `/adddailyupdatedashboard/${values.projectId}/${moment(
      values.dailyUpdateDate
    ).format("YYYYMMDD")}`;
    postRequest(`api/DailyUpdate/AddProjectDailyUpdate`, values).then((res) => {
      // dispatch(getAllEquipmentsRecordsApi());
      //message.success("DailyUpdated Saved successfully");
      dispatch(
        showResultPopupMessage({
          status: "success",
          title: (
            <FormattedMessage id="AddDailyUpdate.NotesCommentssavedsucessfully" />
          ),
          subTitle: "",
          showBackButton: true,
          backButtonRedirectPath: path,
          backButtonName: (
            <FormattedMessage id="AddDailyUpdate.GoBacktoDashboard" />
          ),
        })
      );
    });
    // yield put(hideLoader());
  } catch (e) {
    message.error("Équipement non mis à jour");
    // yield put(hideLoader());
  }
};

export const DownloadADeliveryChallan =
  (dailyUpdateId, supplierId, fileName) => (dispatch) => {
    // yield put(showLoader());
    getRequest(
      `api/DailyUpdate/DownloadDeliveryChallan/${dailyUpdateId}/${supplierId}/${fileName}`
    ).then((res) => {
      var the_file = new Blob([window.atob(res.data.fileContent)], {
        type: "image/png",
        encoding: "utf-8",
      });
      var fr = new FileReader();
      fr.onload = function (oFREvent) {
        var v = oFREvent.target.result.split(",")[1]; // encoding is messed up here, so we fix it
        v = atob(v);
        var good_b64 = btoa(decodeURIComponent(escape(v)));
        good_b64 = `${
          ext[res.data.fileExtension && res.data.fileExtension.toLowerCase()]
        }${good_b64}`;
        const link = document.createElement("a");
        link.href = good_b64;
        link.download = res.data.fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        return good_b64;
      };
      fr.readAsDataURL(the_file);
      // yield put(hideLoader());
    });
  };

export const DeleteDeliveryChallan =
  (dailyUpdateId, sipplierId, fileName) => (dispatch) => {
    // yield put(showLoader());
    getRequest(
      `api/DailyUpdate/DeleteDeliveryChallan/${dailyUpdateId}/${sipplierId}/${fileName}`
    ).then((res) => {
      if (res.data) {
        message.success("Fichier supprimé ..!", [1.0]);
        dispatch(Actions.onGetDUSupplierByDUIdStart(dailyUpdateId));

        return true;
      } else {
        message.error("Échec de la suppression du fichier... !");
        //message.error("File Deletion Failed ...!");
        return false;
      }
      // yield put(hideLoader());
    });
  };
//Reset All Forms
// function* resetAllForms(action) {
//   yield put(Actions.onResetAllForms());
// }
export default function* rootSaga() {
  yield all([
    takeLatest(
      Actions.onDailyUpdateEquipmentSaveStart,
      savePrjectDailyUpdateEquipment
    ),
    takeLatest(
      Actions.onDailyUpdateEquipmentUpdateStart,
      updatePrjectDailyUpdateEquipment
    ),
    takeLatest(Actions.onGetDailyUpdateDetailsStart, getDailyUpdateFullDetails),
    takeLatest(Actions.onEquipmentLookupStart, getEquipmentLookup),
    takeLatest(Actions.onProjetLookupStart, getProjectLookup),
    takeLatest(Actions.onSupplierLookupStart, getSupplierLookup),
    takeLatest(Actions.onSubContractLookupStart, getSubContractorLookup),
    takeLatest(Actions.onMaterialLookupStart, getMaterialLookup),
    takeLatest(Actions.onCategoryLookupStart, getCategoryLookUp),
    takeLatest(
      Actions.onGetEquipmentByDailyUpdateIdStart,
      getDailyUpdateEquipmentById
    ),
    takeLatest(
      Actions.onDUEquipmentDeleteStart,
      deletePrjectDailyUpdateEquipment
    ),
    takeLatest(
      Actions.onGetDUSubcontractorByIdStart,
      getDailyUpdateSubControctorById
    ),
    takeLatest(
      Actions.onGetDUSubContractorByDUIdStart,
      getSubContractorByDailyUpdateId
    ),
    takeLatest(Actions.onDUSubContractorSaveStart, saveProjectDUESubContractor),
    takeLatest(
      Actions.onDUSubContractorUpdateStart,
      updateProjectDUSubContractor
    ),
    takeLatest(
      Actions.onDUSubContactorDeleteStart,
      deletePrjectDUSubContractor
    ),
    takeLatest(Actions.onGetDUSupplierByDUIdStart, getDailyUpdateSupplierById),
    takeLatest(Actions.onGetDUSupplierByDUIdStart, getSupplierByDailyUpdateId),
    takeLatest(Actions.onDUSupplierSaveStart, saveProjectDUESupplier),
    takeLatest(Actions.onDUSupplierUpdateStart, updateProjectDUSupplier),
    takeLatest(Actions.onDUSupplierDeleteStart, deletePrjectDUSupplier),

    takeLatest(Actions.onGetDURentalsByIdStart, getDailyUpdateRentalById),
    takeLatest(Actions.onGetDURentalsByDUIdStart, getRentalByProjectId),
    takeLatest(Actions.onDURentalSaveStart, saveProjectDUERentals),
    takeLatest(Actions.onDURentalUpdateStart, updateProjectDURentals),
    takeLatest(Actions.onDURentalDeleteStart, deletePrjectDURentals),
    // takeLatest(Actions.onResetAllForms, resetAllForms),
  ]);
}

const ext = {
  ".png": "data:image/png;base64,",
  ".jpeg": "data:image/jpeg;base64,",
  ".jpg": "data:image/jpeg;base64,",
  ".pdf": "data:application/pdf;base64,",
  ".xls": "data:application/vnd.ms-excel;base64,",
  ".doc": "data:application/msword;base64,",
  ".docx": "data:application/msword;base64,",
  ".xlsx":
    "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
  ".ppt": "data:application/vnd.ms-powerpoint;base64,",
  ".pptx":
    "data:application/vnd.openxmlformats-officedocument.presentationml.presentation;base64,",
  ".txt": "data:text/plain;base64,",
  ".msg": "data:application/vnd.ms-outlook;base64,",
  ".svg": "data:image/svg+xml;base64,",
  ".zip": "data:application/zip;base64,",
  ".url": "data:application/internet-shortcut;base64,",
};
